<template>
  <Toast></Toast>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '550px' }"
    :header="'Registrar Movimiento de Caja *** Cód: ' + ' ' + data_mov_caja.id"
    :modal="true"
    @hide="ocultarModal"
    class="p-fluid"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-3" v-if="mostrar_fecha_venta">
          <div class="p-inputgroup">
            <strong>FECHA:</strong>
            <Checkbox
              v-model="mov_fecha_diferente"
              :binary="true"
              v-tooltip.top="'Marque si la fecha es diferente a la actual'"
            />
          </div>
          <InputText
            :disabled="!mov_fecha_diferente"
            v-model="data_mov_caja.fecha_mov"
            type="date"
            :max="new Date().toISOString().split('T')[0]"
          />
        </div>
        <div
          :class="
            mostrar_fecha_venta
              ? 'field col-12 md:col-4'
              : 'field col-12 md:col-6'
          "
        >
          <span
            ><strong>T. MOVIMIENTO: </strong>
            <span class="p-invalid">*</span></span
          >
          <Dropdown
            autofocus
            v-model="tipo_mov"
            :options="tp_movimientos"
            optionLabel="label"
            placeholder="Seleccione tipo Movimiento"
          />
          <small class="p-invalid" v-if="errors.tipo_mov">{{
            errors.tipo_mov[0]
          }}</small>
        </div>
        <div
          :class="
            mostrar_fecha_venta
              ? 'field col-12 md:col-5'
              : 'field col-12 md:col-6'
          "
        >
          <span
            ><strong>AUTORIZADO POR: </strong>
            <span class="p-invalid">*</span></span
          >
          <InputText v-model="autorizado_por" />
          <small class="p-invalid" v-if="errors.autorizado_por">{{
            errors.autorizado_por[0]
          }}</small>
        </div>
        <div
          :class="
            metodo_pagoSelect == 1
              ? 'field col-12 md:col-6'
              : 'field col-12 md:col-4'
          "
        >
          <span
            ><strong>M&Eacute;TODO DE PAGO:</strong>
            <small class="p-invalid">*</small></span
          >
          <Dropdown
            v-model="metodo_pagoSelect"
            :options="metodo_pagos"
            optionLabel="nombre"
            class="w-full"
            optionValue="id"
            disabled
          />
        </div>
        <div class="field col-12 md:col-4" v-if="metodo_pagoSelect != 1">
          <span><strong>MONTO:</strong></span>
          <InputNumber
            v-model="importe_metodos_pagos"
            :minFractionDigits="2"
            mode="decimal"
            showButtons
            :inputClass="'text-right'"
            :min="0"
          />
        </div>
        <div
          :class="
            metodo_pagoSelect == 1
              ? 'field col-12 md:col-6'
              : 'field col-12 md:col-4'
          "
        >
          <span
            ><strong>IMPORTE EFECTIVO: </strong
            ><span class="p-invalid">*</span></span
          >
          <InputNumber
            inputClass="text-right"
            v-model="importe"
            :minFractionDigits="2"
            mode="decimal"
            :min="0"
            :showButtons="true"
          ></InputNumber>

          <small class="p-invalid" v-if="errors.importe">{{
            errors.importe[0]
          }}</small>
        </div>
        <div
          v-show="false"
          v-if="tipo_mov.value == 0"
          class="field col-12 md:12"
          style="
            text-align: center;
            border: 1px solid #ccc;
            padding: 10px;
            background-color: #f8f9fa;
            border-radius: 5px;
          "
        >
          <div class="p-inputgroup">
            <span><strong>¿ES ALMUERZO?</strong></span>
            <Checkbox
              v-model="data_mov_caja.almuerzo"
              :binary="true"
              class="ml-1 mr-4"
              @change="toggleSelection('almuerzo')"
              v-tooltip.top="
                'Marque si el movimiento es por concepto de almuerzo'
              "
            />
            <span><strong>¿ES COMISIÓN?</strong></span>
            <Checkbox
              v-model="data_mov_caja.comision"
              :binary="true"
              class="ml-1"
              @change="toggleSelection('comision')"
              v-tooltip.top="
                'Marque si el movimiento es por concepto de comisión'
              "
            />
          </div>
        </div>
        <div class="field col-12">
          <span
            ><strong v-if="data_mov_caja.almuerzo || data_mov_caja.comision"
              >PARA:</strong
            >
            <strong v-else>POR CONCEPTO DE: </strong>

            <span class="p-invalid">*</span></span
          >
          <Textarea
            v-model="concepto"
            rows="1"
            autoResize
            placeholder="Ingrese el concepto del movimiento de caja"
            @keyup.enter="guardarMovCaja"
          />
          <small class="p-invalid" v-if="errors.concepto">{{
            errors.concepto[0]
          }}</small>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="field">
        <span class="flex md:justify-content-start"
          ><strong>CAMPOS REQUERIDOS: </strong>
          <span class="p-invalid">*</span></span
        >
      </div>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="ocultarModal"
        :disabled="!enviado ? false : true"
      />
      <Button
        label="GUARDAR"
        icon="pi pi-save"
        class="p-button-primary p-button-lg"
        @click="guardarMovCaja"
        :disabled="concepto == '' || enviado"
        :loading="enviado"
      />
    </template>
  </Dialog>
</template>
<script>
import CajaService from "@/service/CajaService";
import MetodoPagoService from "@/service/MetodoPagoService.js";

export default {
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    mov_caja: {
      type: Object,
      default() {
        return {};
      },
    },
    mostrar_fecha_venta: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mostrarModal: this.show,
      data_mov_caja: this.mov_caja,
      enviado: false,
      errors: {},
      tipo_mov: { label: "EGRESO", value: 0 },
      tp_movimientos: [
        { label: "INGRESO", value: 1 },
        { label: "EGRESO", value: 0 },
      ],
      autorizado_por: "PROPIETARIO",
      importe: 0,
      concepto: null,
      caja_id: null,
      metodo_pagoSelect: 1,
      metodo_pagos: [],
      importe_metodos_pagos: 0,
      almuerzo: null,
      mov_fecha_diferente: false,
    };
  },
  cajaService: null,
  metodoPagoService: null,
  created() {
    this.cajaService = new CajaService();
    this.metodoPagoService = new MetodoPagoService();
  },
  mounted() {
    this.getMetodoPagos();
  },
  methods: {
    toggleSelection(field) {
      if (field === "almuerzo" && this.data_mov_caja.almuerzo) {
        this.data_mov_caja.comision = false;
      } else if (field === "comision" && this.data_mov_caja.comision) {
        this.data_mov_caja.almuerzo = false;
      }
    },
    getMetodoPagos() {
      this.metodoPagoService.getAllMetodoPagos().then((response) => {
        this.metodo_pagos = response.metodo_pagos;
      });
    },
    guardarMovCaja() {
      if (this.autorizado_por == "" || this.concepto == "") {
        this.$toast.add({
          severity: "error",
          summary: "Movimiento de Caja",
          detail: "Todos los campos son requeridos",
          life: 3000,
        });
        return;
      }
      this.enviado = true;
      let datos = {
        ...this.data_mov_caja,
        tipo_mov: this.tipo_mov.value,
        autorizado_por: this.autorizado_por,
        importe: this.importe,
        concepto: this.concepto,
        caja_id: this.data_mov_caja.id,
        metodo_pago_id: this.metodo_pagoSelect,
        importe_metodos_pagos: this.importe_metodos_pagos,
        mostrar_fecha_venta: this.mostrar_fecha_venta,
        mov_fecha_diferente: this.mov_fecha_diferente,
      };
      this.cajaService.newMovimientoCaja(datos).then((data) => {
        if (Object.defineProperty.hasOwnProperty.call(data, "errors")) {
          this.errors = data.errors;
        } else {
          this.errors = {};
          if (data.success == false) {
            this.$toast.add({
              severity: "error",
              summary: "Movimiento de Caja",
              detail: data.mensaje,
              life: 3000,
            });
            this.enviado = false;
          } else {
            this.$toast.add({
              severity: "success",
              summary: "Movimiento de Caja",
              detail: data.mensaje,
              life: 3000,
            });
            this.$emit("actualizarListado");
            this.ocultarModal();
          }
        }
        this.enviado = false;
      });
    },
    ocultarModal() {
      this.errors = {};
      this.$emit("closeModal");
      this.metodo_pagoSelect = 1;
      this.importe = 0;
      this.concepto = "";
      this.importe_metodos_pagos = 0;
    },
  },
  watch: {
    show(value) {
      this.mostrarModal = value;
    },
    mov_caja(value) {
      this.data_mov_caja = value;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>
